import { JsonRpcProvider, type Overrides, formatUnits } from 'ethers'
import { fetchEVMTokenBalance as fetchTokenBalance } from '../utils/wallet'

export class EthereumClient {
  provider: JsonRpcProvider

  constructor(rpcUrl: string) {
    this.provider = new JsonRpcProvider(rpcUrl)
  }

  async balance(address: any) {
    const b = await this.provider.getBalance(address)
    return parseFloat(formatUnits(b))
  }

  async balances(address: any | Overrides, assets: string[] = []) {
    const ethBalance = { asset: 'ETH.ETH', balance: await this.balance(address) }

    const tokens = assets.filter((asset) => asset.startsWith('ETH.') && asset !== 'ETH.ETH')
    const tokenBalances = await Promise.all(
      tokens.map((asset) => fetchTokenBalance(this.provider, address, asset)),
    )
    return [ethBalance, ...tokenBalances]
  }
}
