import { Contract } from 'ethers/contract'
import type { JsonRpcProvider } from 'ethers/providers'
import { formatUnits } from 'ethers/utils'

export const supportedChainsByWallet: Record<string, Chain[]> = {
  xdefi: ['MAYA', 'THOR', 'ETH', 'ARB', 'BTC', 'KUJI'],
  talisman: ['POLKADOT'],
  keystore: ['MAYA', 'THOR', 'ETH', 'ARB', 'BTC', 'DASH', 'KUJI'],
  leap: ['MAYA', 'KUJI'],
  phantom: ['SOLANA'],
}

export const xDefiAddressIndexes: Record<XDefiChain, number> = {
  THOR: 0,
  ETH: 1,
  BTC: 2,
  KUJI: 3,
  MAYA: 4,
  ARB: 5,
}

export function getAddressIndexForXDefi(chain: Chain): number | null {
  if (supportedChainsByWallet.xdefi.includes(chain)) {
    return xDefiAddressIndexes[chain]
  }
  return null
}

export function isChainSupportedByWallet(wallet: string, chain: Chain): boolean {
  return supportedChainsByWallet[wallet]?.includes(chain)
}

export async function fetchEVMTokenBalance(
  provider: JsonRpcProvider,
  address: string,
  asset: string,
): Promise<AssetBalance> {
  const delim = asset.includes('--') ? '--' : '-'
  const token = '0x' + asset.split(delim)[1].slice(2).toLowerCase()
  const contract = new Contract(
    token,
    ['function decimals() view returns (uint8)', 'function balanceOf(address) view returns (uint)'],
    provider,
  )
  const decimals = await contract.decimals()
  const balance = await contract.balanceOf(address)
  return {
    asset: asset.split('--')[0],
    balance: parseFloat(formatUnits(balance, decimals)),
  }
}
