import { defineStore } from 'pinia'
import { useRouteStore } from './routeStore'
import { CLIENTS } from '~/clients'
import type { Balances } from '~/utils/utils'

interface Lp {
  asset: string
  /**
   * timestamp in ms
   */
  created: number
  units: number
  assetAddress: number
  assetAdded: number
  assetPending: number
  assetWithdrawn: number
  nativeAddress: string
  nativeAdded: number
  nativePending: number
  nativeWithdrawn: number
  assetRedeemable: number
  nativeRedeemable: number
  dualAssetRedeemable: number
  dualNativeRedeemable: number
  singleAssetRedeemable: number
}

export type AssetPrices = { [key: string]: number }
export type WalletsState = {
  wallets: Array<Wallet>
  pools: []
  lps: Record<string, Lp>
  balances: { [key in Wallet['chain']]?: Balances }
  assetPrices: AssetPrices
}
const initialState: WalletsState = {
  wallets: [],
  pools: [],
  lps: {},
  balances: {},
  assetPrices: {},
}
export const useWalletsStore = defineStore('wallets', {
  state: () => initialState,
  getters: {
    lpsAsArray: (state) => Object.values(state.lps),
    isChainConnected: (state) => (chain: string) =>
      state.wallets.some((wallet) => wallet.chain === chain),
    isWalletConnected: (state) => state.wallets.length > 0,

    getAllBalances: (state) => {
      return (balances: WalletsState['balances']) =>
        state.wallets.flatMap((wallet) => {
          return ensureMayaCacaoDisplayed(wallet.chain, balances[wallet.chain] || []).map(
            (balance) => {
              return {
                ...balance,
                chain: wallet.chain,
                address: wallet.address,
                wallet,
              }
            },
          )
        })
    },
  },
  actions: {
    // addWallet(wallet) {
    //   this.wallets.push(wallet)
    // },
    setWallets(newWallets) {
      this.wallets = newWallets
    },
    resetWalletState() {
      this.wallets = []
      this.lps = {}
      localStorage.removeItem('lastWallet')
      localStorage.removeItem('keystore')
      localStorage.removeItem('lastWallet')
    },
    async updateAllBalances() {
      const routeStore = useRouteStore()
      const { routes, lpPools } = routeStore // destructured, so `routes` is not reactive

      // Assets to fetch balances for
      // lpPools: contains staged assets that are missing from routes
      // routes: contains hardcoded assets from integrations that are missing from lpPools (maya)
      const assets = [...new Set([...lpPools.map((lp) => lp.asset), ...routes.map((r) => r.asset)])]

      // Pools to fetch liquidity positions for
      this.pools = await fetchPools()
      const updatedLps: Record<string, Lp> = {}

      // Fetch balances and liquidity positions for each connected wallet
      for (const wallet of this.wallets) {
        CLIENTS[wallet.chain].balances(wallet.address, assets).then((balance) => {
          this.balances[wallet.chain] = balance
        })

        try {
          const { pools: loadedLps } = await apiRequest('/member/' + wallet.address)

          for (const lp of loadedLps) {
            const pool = this.pools.find((p) => p.asset === lp.pool)
            const units = parseInt(lp.liquidityUnits)
            let assetRedeemable = 0
            let nativeRedeemable = 0
            let dualAssetRedeemable = 0
            let dualNativeRedeemable = 0
            let singleAssetRedeemable = 0

            if (lp.runeAdded > 0) {
              assetRedeemable = (pool.balanceAsset * units) / pool.units
              nativeRedeemable = (pool.balanceNative * units) / pool.units
              dualAssetRedeemable = (pool.balanceAsset * units) / pool.units
              dualNativeRedeemable = (pool.balanceNative * units) / pool.units
              singleAssetRedeemable = ((pool.balanceAsset * units) / pool.units) * 2
            } else {
              // Consider if doubling the asset is appropriate or if another approach should be used
              assetRedeemable = ((pool.balanceAsset * units) / pool.units) * 2
              singleAssetRedeemable = ((pool.balanceAsset * units) / pool.units) * 2
              dualAssetRedeemable = (pool.balanceAsset * units) / pool.units
              dualNativeRedeemable = (pool.balanceNative * units) / pool.units
            }

            const lpId = pool.asset + lp.liquidityUnits + lp.assetAdded + lp.runeAdded
            if (!Object.hasOwn(updatedLps, lpId)) {
              updatedLps[lpId] = {
                asset: lp.pool,
                created: parseInt(lp.dateFirstAdded) * 1000,
                units: parseInt(lp.liquidityUnits),
                assetAddress: lp.assetAddress,
                assetAdded: parseInt(lp.assetAdded) / 1e8,
                assetPending: parseInt(lp.assetPending) / 1e8,
                assetWithdrawn: parseInt(lp.assetWithdrawn) / 1e8,
                nativeAddress: lp.runeAddress,
                nativeAdded: parseInt(lp.runeAdded) / 1e10,
                nativePending: parseInt(lp.runePending) / 1e10,
                nativeWithdrawn: parseInt(lp.runeWithdrawn) / 1e10,
                assetRedeemable,
                nativeRedeemable,
                dualAssetRedeemable,
                dualNativeRedeemable,
                singleAssetRedeemable,
              }
            } else {
              //
            }
          }
        } catch (e) {
          // This throws when the api returns 404 on the member endpoint, when an address has no txs
        }
      }

      this.lps = updatedLps

      // Update asset prices

      // Use the asset prices from maya pools instead of coingecko for maya assets
      const pools = await fetchPools()
      pools?.forEach((pool) => {
        if (!this.assetPrices[pool?.asset]) {
          return (this.assetPrices[pool?.asset] = pool?.price)
        }
      })

      function createCoingeckoString(ids: Array<string>): string {
        const queryString = ids.join('%2C')

        return `https://node.eldorado.market/prices/api/v3/simple/price?ids=${queryString}&vs_currencies=usd`
      }

      const coingeckoIds = [
        'polkadot',
        'chainflip',
        'solana',
        'usdc',
        'bonk',
        'zyncoin-2',
        'dogwifcoin',
        'book-of-meme',
        'slerf',
        'maga',
        'pepe',
        'jeo-boden',
        'apu-s-club',
        'tren',
        'ruburt-f-kenidy-jr',
        'ethena',
        'alchemix',
        'yearn-finance',
        'aave',
        'mog-coin',
        'jesus-coin',
        'swag-coin',
        'retardio',
        'upside-down-meme',
        'dark-maga',
      ]

      const coingeckoRequest = await fetch(createCoingeckoString(coingeckoIds))

      const coingeckoPrices = await coingeckoRequest.json()

      // #region assetPrices
      this.assetPrices['DOT.DOT'] = coingeckoPrices.polkadot.usd
      this.assetPrices['ETH.FLIP-0x826180541412D574cf1336d22c0C0a287822678A'] =
        coingeckoPrices.chainflip.usd
      this.assetPrices['SOLANA.SOL'] = coingeckoPrices.solana.usd
      this.assetPrices['SOLANA.BONK--DezXAZ8z7PnrnRJjz3wXBoRgixCa6xjnB7YaB1pPB263'] =
        coingeckoPrices.bonk.usd
      this.assetPrices['SOLANA.ZYN--PzuaVAUH2tfxGZcbBR6kMxeJsBngnsPLFotGJNCtcsd'] =
        coingeckoPrices['zyncoin-2'].usd
      this.assetPrices['SOLANA.ZYN'] = coingeckoPrices['zyncoin-2'].usd
      this.assetPrices['ETH.ZYN--0x58cb30368ceb2d194740b144eab4c2da8a917dcb'] =
        coingeckoPrices['zyncoin-2'].usd
      this.assetPrices['ETH.ZYN'] = coingeckoPrices['zyncoin-2'].usd
      this.assetPrices['SOLANA.WIF--EKpQGSJtjMFqKZ9KQanSqYXRcF8fBopzLHYxdM65zcjm'] =
        coingeckoPrices.dogwifcoin.usd
      this.assetPrices['SOLANA.WIF'] = coingeckoPrices.dogwifcoin.usd
      this.assetPrices['SOLANA.BOME--ukHH6c7mMyiWCf1b9pnWe25TSpkDDt3H5pQZgZ74J82'] =
        coingeckoPrices['book-of-meme'].usd
      this.assetPrices['SOLANA.BOME'] = coingeckoPrices['book-of-meme'].usd
      this.assetPrices['SOLANA.SLERF--7BgBvyjrZX1YKz4oh9mjb8ZScatkkwb8DzFx7LoiVkM3'] =
        coingeckoPrices.slerf.usd
      this.assetPrices['SOLANA.SLERF'] = coingeckoPrices.slerf.usd
      this.assetPrices['ETH.TRUMP--0x576e2bed8f7b46d34016198911cdf9886f78bea7'] =
        coingeckoPrices.maga.usd
      this.assetPrices['ETH.TRUMP'] = coingeckoPrices.maga.usd
      this.assetPrices['ETH.PEPE--0X6982508145454CE325DDBE47A25D4EC3D2311933'] =
        coingeckoPrices.pepe.usd
      this.assetPrices['ETH.PEPE-0X6982508145454CE325DDBE47A25D4EC3D2311933'] =
        coingeckoPrices.pepe.usd

      this.assetPrices['ETH.PEPE'] = coingeckoPrices.pepe.usd
      this.assetPrices['SOLANA.BODEN--3psH1Mj1f7yUfaD5gh6Zj7epE8hhrMkMETgv5TshQA4o'] =
        coingeckoPrices['jeo-boden'].usd
      this.assetPrices['SOLANA.BODEN'] = coingeckoPrices['jeo-boden'].usd

      this.assetPrices['SOLANA.TREN--HLnTNCG5RD7jYVduFc1pMCHiuApoWGn9LveqEFanQFZb'] =
        coingeckoPrices.tren.usd
      this.assetPrices['SOLANA.tren'] = coingeckoPrices.tren.usd

      this.assetPrices['SOLANA.KENIDY--Bg9CZr1CmVoCn2uNWwj9f5rLbmfYRYvcVikCRCwawUwR'] =
        coingeckoPrices['ruburt-f-kenidy-jr'].usd
      this.assetPrices['SOLANA.KENIDY'] = coingeckoPrices['ruburt-f-kenidy-jr'].usd

      this.assetPrices['SOLANA.USDC'] = 1
      this.assetPrices['SOLANA.USDC--EPjFWdd5AufqSSqeM2qN1xzybapC8G4wEGGkZwyTDt1v'] = 1

      this.assetPrices['SOLANA.USDT'] = 1
      this.assetPrices['SOLANA.USDT--Es9vMFrzaCERmJfrF4H2FYD4KCoNkY11McCe8BenwNYB'] = 1

      this.assetPrices['ETH.APU'] = coingeckoPrices['apu-s-club'].usd
      this.assetPrices['ETH.APU--0x594daad7d77592a2b97b725a7ad59d7e188b5bfa'] =
        coingeckoPrices['apu-s-club'].usd

      // this.assetPrices['SOLANA.BARRON'] = coingeckoPrices['???'].usd
      // this.assetPrices['SOLANA.BARRON--HmAgiwjjP9CXqK5wQNsHKtjAt2CH3Kv8Q7xH5kGL2nqZ'] =
      //   coingeckoPrices['???'].usd

      this.assetPrices['ETH.ENA'] = coingeckoPrices.ethena.usd
      this.assetPrices['ETH.ENA--0x57e114B691Db790C35207b2e685D4A43181e6061'] =
        coingeckoPrices.ethena.usd

      this.assetPrices['ETH.ALCX'] = coingeckoPrices.alchemix.usd
      this.assetPrices['ETH.ALCX--0xdbdb4d16eda451d0503b854cf79d55697f90c8df'] =
        coingeckoPrices.alchemix.usd
      this.assetPrices['ETH.YFI'] = coingeckoPrices['yearn-finance'].usd
      this.assetPrices['ETH.YFI--0x0bc529c00c6401aef6d220be8c6ea1667f6ad93e'] =
        coingeckoPrices['yearn-finance'].usd
      this.assetPrices['ETH.AAVE'] = coingeckoPrices.aave.usd
      this.assetPrices['ETH.AAVE--0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9'] =
        coingeckoPrices.aave.usd
      this.assetPrices['ETH.MOG'] = coingeckoPrices['mog-coin'].usd
      this.assetPrices['ETH.MOG--0xaaeE1A9723aaDB7afA2810263653A34bA2C21C7a'] =
        coingeckoPrices['mog-coin'].usd
      this.assetPrices['ETH.JESUS'] = coingeckoPrices['jesus-coin'].usd
      this.assetPrices['ETH.JESUS--0xba386A4Ca26B85FD057ab1Ef86e3DC7BdeB5ce70'] =
        coingeckoPrices['jesus-coin'].usd
      // this.assetPrices['SOLANA.LDR'] = coingeckoPrices[''].usd
      // this.assetPrices['SOLANA.LDR--H9XmJ3ot3MpawSMMxarGqX8rJaQGWd5WWZvnLU4PTwmC'] =
      //   coingeckoPrices[''].usd
      this.assetPrices['SOLANA.SWAG'] = coingeckoPrices['swag-coin'].usd
      this.assetPrices['SOLANA.SWAG--FaxYQ3LVXP51rDP2yWGLWVrFAAHeSdFF8SGZxwj2dvor'] =
        coingeckoPrices['swag-coin'].usd
      this.assetPrices['SOLANA.RETARDIO'] = coingeckoPrices.retardio.usd
      this.assetPrices['SOLANA.RETARDIO--6ogzHhzdrQr9Pgv6hZ2MNze7UrzBMAFyBBWUYp1Fhitx'] =
        coingeckoPrices.retardio.usd

      this.assetPrices['SOLANA.WEWE'] = coingeckoPrices['upside-down-meme'].usd
      this.assetPrices['SOLANA.WEWE--G3Mu6gYiqeEH5vz3qChGB8CV4sW4oUxAqRM67nUVXH1H'] =
        coingeckoPrices['upside-down-meme'].usd

      this.assetPrices['SOLANA.DMAGA'] = coingeckoPrices['dark-maga'].usd
      this.assetPrices['SOLANA.DMAGA--7D7BRcBYepfi77vxySapmeqRNN1wsBBxnFPJGbH5pump'] =
        coingeckoPrices['dark-maga'].usd

      // #endregion
      return {
        balances: this.balances,
        assetPrices: this.assetPrices,
        lps: Object.values(this.lps),
      }
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useWalletsStore, import.meta.hot))
}
