import { ApiPromise, WsProvider } from '@polkadot/api'
import type { u128 } from '@polkadot/types-codec'
import type { Codec } from '@polkadot/types-codec/types'

export class PolkadotClient {
  async balance(address: string) {
    const provider = new WsProvider('wss://rpc.polkadot.io')
    const api = await ApiPromise.create({ provider })
    const result = await api.query.system.account(address)
    return (result as Codec & { data: { free: u128 } }).data.free.toNumber() / 1e10
  }

  async balances(address: string) {
    return [{ asset: 'DOT.DOT', balance: await this.balance(address) }]
  }
}
