const kujiCosmosDirectory = 'https://chains.cosmos.directory/kujira'

export class KujiraClient {
  async balance(address: string) {
    const q: { balances: Array<{ denom: string; amount: number }> } = await (
      await fetch('https://lcd-kujira.whispernode.com/cosmos/bank/v1beta1/balances/' + address)
    ).json()

    const ukuji = q.balances.find((b) => b.denom === 'ukuji')
    const kujiAmount = ukuji ? ukuji.amount / 1e6 : 0
    const uusk = q.balances.find(
      (b) =>
        b.denom ===
        'factory/kujira1qk00h5atutpsv900x202pxx42npjr9thg58dnqpa72f2p7m2luase444a7/uusk',
    )
    const uskAmount = uusk ? uusk.amount / 1e6 : 0
    return [
      { asset: 'KUJI.KUJI', balance: kujiAmount },
      { asset: 'KUJI.USK', balance: uskAmount },
    ]
  }

  async balances(address: string) {
    const balances = await this.balance(address)
    return balances
  }

  async getChainInfo() {
    const chainInfo: {
      chain: { fees: { fee_tokens: Array<{ denom: string; low_gas_price: number }> } }
    } = await fetch(kujiCosmosDirectory).then((res) => res.json())
    return chainInfo
  }

  async getGasPrice(denom: string) {
    const defaultGasPrice = '0.0034ukuji'
    try {
      const chainInfo = await this.getChainInfo()
      const feeAsset = chainInfo.chain.fees.fee_tokens.find((f) => f.denom === denom)
      return feeAsset ? feeAsset.low_gas_price + denom : defaultGasPrice
    } catch {
      return defaultGasPrice
    }
  }
}
