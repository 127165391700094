import { captureException } from '@sentry/browser'

export const INSIGHT_BASE_URL = 'https://insight.dash.org/insight-api'

export class DashClient {
  async balance(address: string) {
    const url = `${INSIGHT_BASE_URL}/addr/${address}/utxo`
    const resp = await fetch(url)
    const text = await resp.text()
    let data
    try {
      data = JSON.parse(text)
    } catch (e) {
      captureException(`error: could parse ${url}:\n${text}`)
      throw e
    }
    if (!resp.ok) {
      throw new Error(`bad response: ${text}`)
    }
    const insightUtxos = data

    if (insightUtxos.length === 0) return 0
    // convert from Insight form to standard form
    const utxos = []
    let total = 0
    for (const insightUtxo of insightUtxos) {
      const utxo = {
        txId: insightUtxo.txid,
        outputIndex: insightUtxo.vout,
        address: insightUtxo.address,
        script: insightUtxo.scriptPubKey,
        satoshis: insightUtxo.satoshis,
      }
      utxos.push(utxo)
      total += insightUtxo.satoshis
    }
    return total / 100000000
  }

  async balances(address: string) {
    return [{ asset: 'DASH.DASH', balance: await this.balance(address) }]
  }
}
