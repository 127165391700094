import { ThorchainClient } from './thorchain'
import { EthereumClient } from './ethereum'
import { BitcoinClient } from './bitcoin'
import { DashClient } from './dash'
import { KujiraClient } from './kujira'
import { PolkadotClient } from './polkadot'
import { SolanaClient } from './solana'
import { ArbitrumClient } from './arbitrum'

const ETHEREUM_DEV_NETWORK: string = 'mainnet' // 'goerli' for testnet, 'mainnet' for mainnet
const USE_ETH_NETWORK = process.env.NODE_ENV === 'development' && ETHEREUM_DEV_NETWORK === 'goerli'
const ETH_NETWORK = USE_ETH_NETWORK ? 'goerli' : 'mainnet'
const ethRpcUrl = `https://eth-${ETH_NETWORK}.g.alchemy.com/v2/y8uEgC1cDd_N_8tIhZJDRPYFDnMUaywQ`
const arbRpcKey = 'BU8TokIdf3qW_vUV2DJZsJVTv3YQcOb3'

export const CHAINS = ['MAYA', 'THOR', 'ETH', 'BTC', 'DASH', 'KUJI', 'POLKADOT', 'ARB']

export const CHAINS_NAMES = {
  MAYA: 'MAYAChain',
  THOR: 'THORChain',
  ETH: 'Ethereum',
  ARB: 'Arbitrum',
  BTC: 'Bitcoin',
  DASH: 'Dash',
  KUJI: 'Kujira',
  SOLANA: 'Solana',
}

export const CHAINS_NATIVE_ASSET = {
  MAYA: 'CACAO',
  THOR: 'RUNE',
  ETH: 'ETH',
  ARB: 'ETH',
  BTC: 'BTC',
  DASH: 'DASH',
  KUJI: 'KUJI',
  POLKADOT: 'DOT',
}

export const CLIENTS = {
  MAYA: new ThorchainClient('https://node.eldorado.market/midgard', 'CACAO'),
  THOR: new ThorchainClient('https://midgard.ninerealms.com', 'THOR.RUNE'),
  ETH: new EthereumClient(ethRpcUrl),
  ARB: new ArbitrumClient(arbRpcKey),
  BTC: new BitcoinClient(),
  DASH: new DashClient(),
  KUJI: new KujiraClient(),
  POLKADOT: new PolkadotClient(),
  SOLANA: new SolanaClient(),
}
