export class ThorchainClient {
  midgardUrl: string
  asset: string

  constructor(midgardUrl: string, asset: string) {
    this.midgardUrl = midgardUrl
    this.asset = asset
  }

  async balance(address: string) {
    const coins = (await this.request('/balance/' + address)).coins
    const coin = coins.find((c: any) => c.asset === this.asset)
    if (!coin) return 0
    return parseFloat(coin.amount) / (coin.asset === 'CACAO' ? 1e10 : 1e8)
  }

  async balances(address: string) {
    if (this.asset !== 'CACAO') {
      return [{ asset: this.asset, balance: await this.balance(address) }]
    }
    const coins = (await this.request('/balance/' + address)).coins
    return coins.map((c: any) => ({
      asset: c.asset.includes('/') ? c.asset : 'MAYA.' + c.asset,
      balance: parseFloat(c.amount) / (c.asset === 'MAYA' ? 1e4 : c.asset === 'CACAO' ? 1e10 : 1e8),
    }))
  }

  async request(path: string) {
    return await (await fetch(this.midgardUrl + '/v2' + path)).json()
  }
}
