import { RangoClient } from 'rango-sdk-basic'

export class SolanaClient {
  // async balance(address: string) {
  //   const nodeUrl = 'https://node.eldorado.market/helius'
  //   const connection = new Connection(nodeUrl)
  //   const pubKey = new PublicKey(address)
  //   const balance = await connection.getBalance(pubKey)
  //   const lamports = balance / 1e9
  //   return lamports
  // }

  async balances(address: string) {
    const rangoClient = new RangoClient('', false, 'https://node.eldorado.market/rango')
    const balances = await rangoClient.balance({
      blockchain: 'SOLANA',
      address,
    })

    // Filter wallets for SOLANA blockchain and transform the balances
    const solanaBalances = balances.wallets
      .filter((wallet) => wallet.blockChain === 'SOLANA') // Ensure blockchain is SOLANA
      .flatMap(
        (balance) =>
          balance.balances?.map(({ amount, asset }) => ({
            asset: `${asset.blockchain}.${asset.symbol}`.toUpperCase(),
            balance: Number(amount.amount) / 10 ** amount.decimals, // TODO use BigNumber
          })) ?? [],
      )

    return solanaBalances
  }
}
