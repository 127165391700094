export class BitcoinClient {
  async balance(address: string) {
    const url = 'https://blockchain.info'
    // const url2 = 'https://blockchair.info'
    let b
    try {
      b = await (await fetch(url + '/q/addressbalance/' + address)).text()
    } catch {
      b = (
        await (
          await fetch('https://api.blockchair.com/bitcoin/addresses/balances?addresses=' + address)
        ).json()
      ).data
      b = b[address]
      if (!b) b = 0
    }
    return parseFloat(b) / 1e8
  }

  async balances(address: string) {
    return [{ asset: 'BTC.BTC', balance: await this.balance(address) }]
  }
}
