import { AlchemyProvider, type Overrides, formatUnits } from 'ethers'
import { fetchEVMTokenBalance as fetchTokenBalance } from '../utils/wallet'

export class ArbitrumClient {
  provider: AlchemyProvider

  constructor(arbRpcKey: string) {
    this.provider = new AlchemyProvider('arbitrum', arbRpcKey)
  }

  async balance(address: any) {
    const b = await this.provider.getBalance(address, 'latest')

    return parseFloat(formatUnits(b))
  }

  async balances(address: any | Overrides, assets: string[] = []) {
    const arbEthBalance = { asset: 'ARB.ETH', balance: await this.balance(address) }

    const arbTokens = assets.filter((asset) => asset.startsWith('ARB.') && asset !== 'ARB.ETH')
    const tokenBalances = await Promise.all(
      arbTokens.map((asset) => fetchTokenBalance(this.provider, address, asset)),
    )
    return [arbEthBalance, ...tokenBalances]
  }
}
